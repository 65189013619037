@font-face {
  font-family: "Checkout-Regular";
  src: local("Checkout-Regular"),
   url("./Checkout-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Checkout-Medium";
  src: local("Checkout-Medium"),
   url("./Checkout-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Checkout-Bold";
  src: local("Checkout-Bold"),
   url("./Checkout-Bold.ttf") format("truetype");
  font-weight: bold;
}
